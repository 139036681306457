import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

export function getPlageWeek(dateTo:string|null, idCentre:number|null, filters:boolean=false, filtersV=false):Promise<Plage[]>{
    let parms = `dateTo=${dateTo}`
    if(idCentre){
        parms+= `&idCentre=${idCentre}`
    }
    if(filters){
        parms+= `&filtersUser=1`
    }
    if(filtersV){
        parms+= `&filtersVues=1`
    }
    return dataFetcher(`plages/get_week_plage?${parms}`, {method:'get'})
}

export async function getPlagesByParams2(params:any|null):Promise<Plage[]>{
    const TabSearch:string[] = [];
    if(params) {
        for (const [key, value] of Object.entries(params)){
            TabSearch.push(`${key}=${value}`)
        }
    }
    const search = TabSearch.length>0 ? `&${TabSearch.join('&')}` : ``
    const res:any = await dataFetcher(`plages?pagination=false${search}`, {method:'get'})
    return res["hydra:member"];
}

export function getPlageWeekOne(dateTo:string|null, id:number):Promise<Plage[]>{
    return dataFetcher(`plages/get_week_plage?dateTo=${dateTo}&User=${id}`, {method:'get'})
}

export function getPlageId(id:number):Promise<Plage>{
    return dataFetcher(`plages/${id}`, {method:'get'})
}

export async function getPlagePeriodeAbs(Periode:Periode, idUser:number):Promise<Plage[]>{
    const dateFrom = new Date(Periode.debutAt.slice(0.10)).toISOString().slice(0,10);
    const dateTo = new Date(Periode.finAt.slice(0.10)).toISOString().slice(0,10);
    const idActe = Periode.Type.Acte.id;
    const request = `plages?plageAt[after]=${dateFrom}&plageAt[before]=${dateTo}&Acte=${idActe}&User=${idUser}`
    const res:any = await dataFetcher(request)
    return res["hydra:member"];
}

export async function  getPlagesDateToDate(dateFrom:string, dateTo:string, id:number|undefined, notStrict:boolean=false, idCentre:number|null=null):Promise<Plage[]>{
    let request = `plages?plageAt[after]=${dateFrom}&plageAt[strictly_before]=${dateTo}`
    if(notStrict){
        request = `plages?plageAt[after]=${dateFrom}&plageAt[before]=${dateTo}`
    }
    if(id!==undefined){
        request = request + `&User=${id}`
    }
    if(idCentre){
        request+=`&Centre=${idCentre}`
    }
    const res:any = await dataFetcher(request)
    return res["hydra:member"];
}
export async function  getPlagesDateToDateMulti(dateFrom:string|undefined, dateTo:string|undefined, ids:number[], idCentre:number|null):Promise<Plage[]>{
    const idsStrA = ids.map(item=>`User[]=${item}`);
    const idsStrB = idsStrA.join('&')
    let parms = `plageAt[after]=${dateFrom}&plageAt[strictly_before]=${dateTo}&${idsStrB}`
    if(idCentre){
        parms+=`&Centre=${idCentre}`
    }
    const res:any = await dataFetcher(`plages?${parms}`)
    return res["hydra:member"];
}
export async function  getPlagesDate(date:string, id:number|undefined):Promise<Plage[]>{
    const res:any = await dataFetcher(`plages?plageAt=${date}&User=${id}`)
    return res["hydra:member"];
}

export async function  getPlagesReplacedDate(date:string, id:number|undefined):Promise<Plage[]>{
    const res:any = await dataFetcher(`plages?plageAt=${date}&UserReplaced=${id}`)
    return res["hydra:member"];
}

export async function  getPlagesDateLieu(date:string, id:number|null, idCentre:number|null):Promise<Plage[]>{
    let parms = `plageAt=${date}&Lieu=${id}`
    if(idCentre){
        parms+=`&Centre=${idCentre}`
    }
    const res:any = await dataFetcher(`plages?${parms}`)
    return res["hydra:member"];
}

export function modifyPlage(datas:ModifyPlageFormData):Promise<Plage>{
    return dataFetcher(`plages/${datas.id}`, {
        method:'put',
        body:datas
    })
}

export function addPlage(datas:AddPlageFormData):Promise<Plage>{
    return dataFetcher(`plages`, {
        method:'post',
        body:datas
    })
}

export function addMultiPlage(datas:AddPlageFormMulti[]):Promise<Plage[]>{
    return dataFetcher(`plages/multi_post`, {
        method:'post',
        body: {plages:datas}
    })
}

export function modifyMultiPlage(datas:ModifyMultiPlageFD):Promise<Plage[]>{
    return dataFetcher(`plages/multi_modif`, {
        method:'post',
        body: datas
    })
}

export function outTeam(id:number):Promise<Plage>{
    return dataFetcher(`plages/${id}`, {
        method:'put',
        body: {rang:null}
    })
}

export function replacePlage(datas:PlageReplaceFormData):Promise<Plage>{
    return dataFetcher(`plages/${datas.id}`, {
        method:'put',
        body:{User:datas.idUser, verifHorse:false}
    })
}

export function putTeam(data:PutTeamFormData):Promise<Plage>{
    return dataFetcher(`plages/${data.id}`, {
        method:'put',
        body: data
    })
}

export function trashPlage(id:number):Promise<Plage>{
    return dataFetcher(`plages/${id}`, {
        method:'DELETE',
    })
}

export function trashMultiPlage(ids:string):Promise<Plage[]>{
    return dataFetcher(`plages/delete_multi`, {
        method:'delete',
        body:{plages:ids}
    })
}

export function getInfosPlageAbs(id:number):Promise<InfosPlageAbs>{
    return dataFetcher(`get_infos_valo_abs/${id}`)
}

export function changeValoAbs(id:number, myTime:number):Promise<Plage>{
    return dataFetcher(`get_change_valo_abs/${id}`, {
        method:"POST",
        body:{myTime:myTime}
    })
}