import {useQuery, useQueryClient, UseQueryResult} from "react-query";
import {getAllVues} from "./vuePlanning.services";

export const useGetVuePl = (idCentre:number|null=null):UseQueryResult<VuePlanning[], Error>=>{
    let idCentreX = idCentre;
    const queryClient = useQueryClient();
    const UserConnected: UserSimple | undefined = queryClient.getQueryData(["user_connected"]);
    const idCentreXConnected = UserConnected ? UserConnected.Centre.id : 0;
    const idCentreFinal = idCentreX ? idCentreX : idCentreXConnected
    return useQuery<VuePlanning[], Error>(["vue_plannings", idCentre], ()=>getAllVues(idCentreFinal), {
        staleTime:60000,
        enabled:idCentreFinal!==0
    })
}