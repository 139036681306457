import {useQuery, UseQueryResult} from "react-query";
import {getAllLieuHorairesByCentre, getAllLieuHorairesByLieu} from "./lieuHoraires.services";

export const useGetAllLieuHorairesByLieu = (id:number):UseQueryResult<LieuHoraires[], Error>=>{
    return useQuery(["lieu_horaires", id], ()=>getAllLieuHorairesByLieu(id), {
        enabled:id!==0
    })
}

export const useGetAllLieuHorairesByCentre = (id:number):UseQueryResult<LieuHoraires[], Error>=>{
    return useQuery(["lieu_horaires_centre", id], ()=>getAllLieuHorairesByCentre(id), {
        enabled:id!==0
    })
}