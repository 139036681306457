import logo from '../images/logo.png';

const RestimeTheme = {
    TextPrimaryLight:"#F6CFB6",
    TextPrimaryDark:"#6D320D",

    TextSecondaryLight:"#FAE6B3",
    TextSecondaryDark:"#AC7E0C",

    TextComplementaryLight:"#DAD0F1",
    TextComplementaryDark:"#381228",

    TextTertiaryLight:"#DCC5BC",
    TextTertiaryDark:"#432C23",

    TextNeutreLight:"#E3DEE0",
    TextNeutreDark:"#574C51",


    PrimaryMegaDark:"#361907",
    PrimaryExtraDark:"#6D320D",
    PrimarySuperDark:"#914312",
    PrimaryDark:"#B65316",
    Primary:"#CE5F1A",
    PrimaryLight:"#E67A37",
    PrimarySuperLight:"#EFAB80",
    PrimaryExtraLight:"#F6CFB6",
    PrimaryMegaLight:"#F2E7E1",


    SecondaryMegaDark:"#775701",
    SecondaryExtraDark:"#AC7E0C",
    SecondarySuperDark:"#AD7C00",
    SecondaryDark:"#D89B00",
    Secondary:"#E6AA10",
    SecondaryLight:"#EDC96D",
    SecondarySuperLight:"#B7CC90",
    SecondaryExtraLight:"#FAE6B3",
    SecondaryMegaLight:"#FCF2D9",


    ComplementaryMegaDark:"#22133F",
    ComplementaryExtraDark:"#381228",
    ComplementarySuperDark:"#3C216E",
    ComplementaryDark:"#6738BC",
    Complementary:"#7F56CC",
    ComplementaryLight:"#8D6FC7",
    ComplementarySuperLight:"#B8A1E3",
    ComplementaryExtraLight:"#DAD0F1",
    ComplementaryMegaLight:"#DEDBE6",

    TertiaryMegaDark:"#281A15",
    TertiaryExtraDark:"#432C23",
    TertiarySuperDark:"#50352A",
    TertiaryDark:"#6B4738",
    Tertiary:"#835745",
    TertiaryLight:"#A06A54",
    TertiarySuperLight:"#B2806C",
    TertiaryExtraLight:"#DCC5BC",
    TertiaryMegaLight:"#DED4D0",



    NeutreMegaDark:"#333333",
    NeutreExtraDark:"#574C51",
    NeutreSuperDark:"#97878E",
    NeutreDark:"#97878E",
    Neutre:"#B1A6AB",
    NeutreLight:"#C6BEC1",
    NeutreSuperLight:"#D0C8CC",
    NeutreExtraLight:"#E3DEE0",
    NeutreMegaLight:"#F8F8F8",

    Light:"#F5F0ED",
    LightDark:"#E8D6CC",
    LightExtraLight:"#FFFFFF",

    Dark:"#1F2041",
    DarkDark:"#141111",

    SuccessMegaDark:"#0C1109",
    SuccessExtraDark:"#27351C",
    SuccessSuperDark:"#42592F",
    SuccessDark:"#5D7D42",
    Success:"#78A255",
    SuccessLight:"#97B77B",
    SuccessSuperLight:"#B6CCA1",
    SuccessExtraLight:"#D5E1C7",
    SuccessMegaLight:"#F2F6EE",


    ErrorMegaDark:"#120808",
    ErrorExtraDark:"#3B1A1C",
    ErrorSuperDark:"#791700",
    ErrorDark:"#A21F00",
    Error:"#BC2400",
    ErrorLight:"#C64E31",
    ErrorSuperLight:"#E87256",
    ErrorExtraLight:"#E39785",
    ErrorMegaLight:"#F2D5CE",

    WarningMegaDark:"#481E0F",
    WarningExtraDark:"#9A5E18",
    WarningSuperDark:"#B47600",
    WarningDark:"#CC9008",
    Warning:"#E69F00",
    WarningLight:"#FABA53",
    WarningSuperLight:"#F7C87B",
    WarningExtraLight:"#FCDBB5",
    WarningMegaLight:"#FFE5C7",

    Info:"#509AB5",
    InfoDark:"#266880",
    InfoLight:"#98C7D9",

    BodyBackground:"#EDECEA",
    MenuBackground:"#403E3E",


    MenuBackgroundGreyVersion:"#333333",
    TextMenuColor:"#F6CFB6",

    colorDark:"#2E1F27",
    colorLight:"#FFFFFF",
    colorBackInput:"#F5F4F8",

    greyForm:"#F4F2F7",

    logo:logo,
}

export default RestimeTheme;