import styled from "styled-components";
import {useGetCentreActuel} from "../../features/centre/centre.hooks";
import {useMemo} from "react";
import {useGetAllUserSub, useSaveWMSub} from "../../features/user/user.hooks";
import {useSearchParams} from "react-router-dom";

interface FormSwitchCentreCtrlProps {
    className?:string;
}
interface OneSub{
    idCentre:number;
    libelle:string;
    idUser:number;
}

const FormSwitchCentreCtrl = ({className}: FormSwitchCentreCtrlProps) => {
    const CentreActuelQuery = useGetCentreActuel();
    const UsersQuery = useGetAllUserSub();
    const mutation = useSaveWMSub();
    const [searchParams, setSearchParams] = useSearchParams();
    const handleClickConnexion = (item:OneSub)=>{
        const datas: WMsubFormData = {
            SubstituateBy: `/api/users/${item.idUser}`
        }
        mutation.mutate((datas), {
            onSuccess: () => {
                setSearchParams({});
                window.location.reload();
            }
        })
    }
    const listCentreSub = useMemo(()=>{
        const list:OneSub[] = [];
        if(UsersQuery.data){
            const UserSub = UsersQuery.data.filter(u=>u.isSubstituable);
            UserSub.forEach(u=>{
                list.push({
                    idCentre:u.Centre.id,
                    libelle:u.Centre.name,
                    idUser:u.id
                })
            })
        }
        return list;
    }, [UsersQuery.data])
    return (
        <div className={`change_structure ${className}`}>
            {listCentreSub.map(c=>(
                <div className={`one_centre_sub ${c.idCentre === CentreActuelQuery.data?.id ? 'current' : ''}`} key={`OnCentre${c.idCentre}`} onClick={()=>handleClickConnexion(c)}>
                    {c.libelle}
                </div>
            ))}
        </div>
    )
}

export const FormSwitchCentre = styled(FormSwitchCentreCtrl)`
    .one_centre_sub{
        width: 100%;
        padding: 0.25rem 0.5rem;
        text-align: center;
        &.current, &:hover{
            cursor: pointer;
            background: ${props => props.theme.PrimaryMegaLight};
        }
    }
`