import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {addActe, changeOrdreActe, getActeById, getAllActes, modifyActe, switchActeMaster} from "./acte.services";
import {toast} from "react-toastify";
import {changeOrdreLieu} from "../lieu/lieu.services";

export const useGetAllActes = (idCentre:number|null=null):UseQueryResult<Acte[], Error>=>{
    const queryClient = useQueryClient();
    if(!idCentre) {
        const userConnected: User | undefined = queryClient.getQueryData(["user_connected"]);
        idCentre = userConnected ? userConnected.Centre.id : 0;
    }
    return useQuery<Acte[], Error>(["actes", idCentre], ()=>getAllActes(idCentre||0), {
        staleTime:5000,
        enabled:idCentre!== null && idCentre!==0
    })
}

export const useGetActeById = (id:number):UseQueryResult<Acte, Error>=>{
    return useQuery<Acte, Error>(["acte", id], ()=>getActeById(id), {
        enabled:id!==0
    })
}

export const useModifyActe = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:{id:number, datas: ActeFD})=>{
            return modifyActe(data.id, data.datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["actes"]);
            }, onError:()=>{
                toast.error("impossible de modifier l'acte")
            }
        }
    )
}

export const useAddActe = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:ActeFD)=>{
            return addActe(datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["actes"]);
                toast.success("Acte créé")
            }, onError:()=>{
                toast.error("impossible de créer acte")
            }
        }
    )
}

export const useChangeOrdreActe = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:ActesChangeOrdre)=>{
            return changeOrdreActe(data);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["actes"]);
            },
            onError:()=>{
                toast.error("Une erreur est survenue merci de retenter plus tard")
            }
        }
    )
}

export const useSwitchActeMaster = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return switchActeMaster(id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["actes"]);
            },
            onError:()=>{
                toast.error("Une erreur est survenue merci de retenter plus tard")
            }
        }
    )
}